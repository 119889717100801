/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable testing-library/no-debugging-utils */
/* eslint-disable max-lines-per-function */
import "core-js/stable";
import { DBSchema, openDB } from "idb";
import "regenerator-runtime/runtime";
import "whatwg-fetch";
import { loadStateFromDB, saveState } from "./design/state";
import "./design/styles";
import { configureStore, RootState } from "./lib/configureStore";
import validate from "./lib/configureStore.validator";
import { startup, startupPrivate } from "./lib/design";
import log from "./lib/log";
import { EcogardenObjects } from "./lib/objects";
import { v4 as uuidv4 } from "uuid";

// Setup redux store with local state

export interface EcogardenDB extends DBSchema {
	readonly objects: {
		readonly key: string;
		readonly value: EcogardenObjects;
	};
	readonly db: {
		readonly key: string;
		readonly value: Partial<RootState>;
	};
}

const loadUI = () => {
	return import(
		/* webpackChunkName: "ui" */
		/* webpackPrefetch: true */
		/* webpackPreload: true */
		"./lib/ui"
	);
};

// TODO Move this elsewhere, maybe rework it so it's less raw
const fatalErrorScreen = (e: unknown) => {
	const block = document.createElement("div");
	block.innerHTML = `<div style="margin: 1em; display: grid; justify-content: center;"><div>Error loading design. Fatal error. Please report this error. Thank you.</div><div style="overflow: auto; height: 30em; padding: 1em 0; max-height: 75vh;width: 60em; max-width: 75vw; font-size: 0.8em; text-align: initial; user-select: text; ">${
		typeof e === "object" && e && "message" in e ? (e as Error).message : ""
	}</div></div>`;
	block.style.textAlign = "center";
	block.style.display = "grid";
	block.style.justifyContent = "center";
	block.style.alignItems = "center";
	block.style.backgroundColor = "var(--accent-bg-color)";
	block.style.position = "absolute";
	block.style.left = "0";
	block.style.top = "0";
	block.style.width = "100%";
	block.style.height = "100%";
	block.style.zIndex = "3";
	block.style.opacity = "0.9";
	block.style.fontFamily = "monospace";
	document.body.appendChild(block);
	document?.querySelector("#loading")?.classList.remove("active");
};

(() => {
	const loading = document.querySelector("#loading");

	if (!loading) {
		console.error("Could not find a loading screen.");
		return;
	}

	// TODO: we can't save to indexedDB so we will need to work without a DB
	if (!window.indexedDB) {
		startupPrivate({ designId: uuidv4() })(loadUI());
		return;
	}

	// TODO: Remove local storage restore after 9 months on Dec 31 2022
	const localStorageState = window.localStorage.getItem("state");
	openDB<EcogardenDB>("EcogardenDB", 2, {
		// eslint-disable-next-line max-params
		upgrade(db, oldVersion, newVersion, transaction) {
			switch (newVersion) {
				case 2:
					db.createObjectStore("db");
			}
		},
		// eslint-disable-next-line max-lines-per-function, complexity
	})
		.then((db) => {
			// TODO Remove legacy support on Dec 31 2022
			// Handle legacy load local storage state and migrate to
			if (localStorageState) {
				// eslint-disable-next-line functional/no-try-statement
				try {
					log.debug(() => "Loading from local storage, state");
					// loading from local storage
					const state = validate(JSON.parse(localStorageState));
					const store = configureStore(state);
					// once upgraded save to indexeddb
					saveState(db)(store)([])();

					log.debug(() => "Startup from local storage");
					startup(db)(store.getState())(loadUI());

					log.debug(() => "Removing item from local storage, state");
					// now we should remove the localStorage state.
					window.localStorage.removeItem("state");
				} catch (e) {
					console.log("error loading state", e);
					fatalErrorScreen(e);
				}
			} else {
				log.debug(() => "Loading state from IndexedDB");
				loadStateFromDB(db)
					.then((loadedState) => {
						// Could not load state. Startup new design.
						if (loadedState === undefined) {
							log.debug(() => "loaded state was undefined");
							startup(db)({ designId: uuidv4() })(loadUI());
							return;
						}

						// Validation will throw an error on failure
						// eslint-disable-next-line functional/no-try-statement
						try {
							const state = validate(loadedState);

							log.debug(() => "Validation successful.");
							startup(db)(state)(loadUI());
						} catch (e) {
							log.debug("Error loading state. Updating to blank state...", e);
							// If not loaded, start a brand new design
							startup(db)({ designId: uuidv4() })(loadUI());

							fatalErrorScreen(e);
							// should have a way to transition this into a new design or backup the previous design.
						}
					})
					.catch((err) => {
						console.log("Could not load the state", err);
						startup(db)({ designId: uuidv4() })(loadUI());
					});
			}
		})
		.catch((e) => {
			console.error("Could not setup DB.", e?.message);
		});
})();
